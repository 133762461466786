import { withDefaultsParams } from '@/common';
import request from '@/utils/request';
import xpack from '@/utils/xpack';
import xrequest from '@/utils/xrequext';

// 获取产品标签列表
// 接口地址：https://www.apifox.cn/link/project/2492336/apis/api-73053659
export const getProductLabelList = (data = {}) => {
  return request.post('/api/productTag/productTagList', data);
};

// 根据销售类别，获取销售产品的品牌列表
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-71784379
export const productBrandList = data => {
  return request.post('/api/productSale/productBrandList', data);
};

// 根据颜色类别和销售类别，获取销售产品的颜色列表
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-71784440
export const productColorList = data => {
  return request.post('/api/productSale/productColorList', data);
};

// 根据销售类别，获取销售产品的级别列表
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-71784464
export const productGradeList = data => {
  return request.post('/api/productSale/productGradeList', data);
};

// 根据销售类别，获取销售产品的大类列表
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-71784811
export const productMainCategoryList = data => {
  return request.post('/api/productSale/productMainCategoryList', data);
};

// 根据产品大类和销售类别，获取销售产品的小类列表
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-71784849
export const productSubCategoryList = data => {
  return request.post('/api/productSale/productSubCategoryList', data);
};

// 根据销售类别等条件，获取销售的产品列表(根据产品名称和等级汇总)
export const productSaleSumListBySaleType = data => {
  return request.post('/api/productSale/productSaleSumListBySaleType', data);
};

// 根据销售类别等条件，获取销售的产品列表
export const productSaleListBySaleType = data => {
  return request.post('/api/productSale/productSaleListBySaleType', data);
};

// 获取当前登录会员是否可以显示现售和拍售，若可显示返回true,否则返回false
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-72346166
export const isShowTodaySale = data => {
  return request.post('/api/productSale/isShowTodaySale', data);
};

// PC端的预订类型时（APP的“更多时间”）显示的可以选择的最小日期和最大日期
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-72351349
export const presellMaxAndMinDate = data => {
  return request.post('/api/productSale/presellMaxAndMinDate', data);
};

// 现售列表的订购接口
// 接口地址：https://www.apifox.cn/link/project/2492336/apis/api-73128227
export const createCurrentOrder = data => {
  return request.post('/api/productSale/doOrderDg', data, { headers: { showLoading: true } });
};

// 预订列表的预订接口
// 接口地址：https://www.apifox.cn/link/project/2492336/apis/api-73128253
export const createBookingOrder = data => {
  return request.post('/api/productSale/doOrderYd', data, { headers: { showLoading: true } });
};

// 显售、预售、拍售列表的竞购接口
// 接口地址：https://www.apifox.cn/link/project/2492336/apis/api-73128240
export const createAuctionOrder = data => {
  return xpack.post('/orderBid/doOrderBid', data, { headers: { showLoading: true } });
};

// 明天，后天，大后天列表的订购接口
// 接口地址：https://www.apifox.cn/link/project/2492336/apis/api-73128252
export const createComingOrder = data => {
  return request.post('/api/productSale/doOrderOtt', data, { headers: { showLoading: true } });
};

// 已购货单列表接口
// 接口地址：https://www.apifox.cn/link/project/2492336/apis/api-73128231
export const getPurchasedOrderList = (data = {}) => {
  return request.post('/api/orderDetail/orderDetailList', data);
};

// 竞购订单列表接口
// 接口地址：https://www.apifox.cn/link/project/2492336/apis/api-73128243
export const getOrderBidList = (data = {}) => {
  return request.post('/api/orderBid/orderBidList', data);
};

// 预订订单列表接口
// 接口地址：https://www.apifox.cn/link/project/2492336/apis/api-73128257
export const getBookOrderDetailList = (data = {}) => {
  return request.post('/api/bookOrderDetail/bookOrderDetailList', data);
};

// 已购货单列表操作列的修改、删除接口(若订购数传0即orderCount=0,则取消该订单)
// 接口地址：https://www.apifox.cn/link/project/2492336/apis/api-73128236
export const updateOrderDetailSave = (data = {}) => {
  return request.post('/api/orderDetail/orderDetailSave', data, { headers: { showLoading: true } });
};

// 竞购订单列表操作列的修改竞购订单接口
// 接口地址：https://www.apifox.cn/link/project/2492336/apis/api-73128247
export const updateOrderBidSave = (data = {}) => {
  return xpack.post('/orderBid/orderBidSave', data, { headers: { showLoading: true } });
};

// 预订订单列表操作列的修改、删除预订订单接口(若预订数传0即orderCount=0,则取消该订单)
// 接口地址：https://www.apifox.cn/link/project/2492336/apis/api-73128259
export const updateBookOrderDetailSave = (data = {}) => {
  return request.post('/api/bookOrderDetail/bookOrderDetailSave', data, { headers: { showLoading: true } });
};

// 全正价提前提交接口
// 接口地址：https://www.apifox.cn/link/project/2492336/apis/api-73715799
export const submitOrderAdvance = (data = {}) => {
  return request.post('/api/productSale/submitOrderAdvance', data);
};

// 保存我的最爱
// 接口地址：https://www.apifox.cn/link/project/2492336/apis/api-70838474
export const saveMyLove = (data = {}) => {
  return request.post('/api/myLove/myLoveSave', data);
};

// 删除我的最爱
// 接口地址：https://www.apifox.cn/link/project/2492336/apis/api-70838474
export const deleteMyLove = (data = {}) => {
  return request.post('/api/myLove/myLoveDelete', data);
};

// 获取会员客户经理
// 接口地址：https://www.apifox.cn/link/project/2492336/apis/api-73806948
export const getMyProductManager = (data = {}) => {
  return request.post('/api/member/myProductManager', data);
};

// 获取产品详情接口
// 接口地址：https://www.apifox.cn/link/project/2492336/apis/api-74985666
export const getProductDetail = (data = {}) => {
  return request.post('/api/productSale/productSaleInfo', data);
};

// 获取产品详情接口
// 接口地址：https://www.apifox.cn/link/project/2492336/apis/api-74985666
export const getProductDetailRateInfo = (data = {}) => {
  return request.post('/api/productSale/productSaleRateInfo', data);
};

// 取投诉列表
export const getComplainList = (data = {}) => {
  return request.post('/api/complain/myComplainList', data);
};

// 保存投诉内容
export const saveComplain = (data = {}) => {
  return request.post('/api/complain/complainSave', data);
};

// 取竞购产品列表
export const getOrderBidMyBidList = (data = {}) => {
  return request.post('api/orderBid/myBidList', data);
};

// 取竞购结果列表
export const getOrderBidResultList = (data = {}) => {
  return request.post('api/orderBid/bidResultList', data);
};

// 获取明天、后天、大后天的类型名称和产品数量
export const productSaleCount = (data = {}) => {
  return request.post('api/productSale/productSaleCount', data);
};

// 聚合支付码
export const recharge = (data = {}) => {
  return xpack.post('/polypay/recharge', data);
};

// 订单查询
export const orderQuery = orderNo => {
  return xpack.post('/polypay/query', {
    orderNo
  });
};

// 取产品详情评价列表
export const getProductEvaluateList = (data = {}) => {
  return request.post('api/orderEvaluate/orderEvaluateList', data);
};

// 取产品详情
export const getProductDetailWithCode = (data = {}) => {
  return request.post('api/product/productInfoWithCode', data);
};


// =====================新的晚场订购===========================
/**
 * 获取预售产品大类列表
 */
export const findPresaleMainCategory = withDefaultsParams({
  sale_date: "", keywords: "", brand_code: "", color: "", grade: ""

})((data) => {
  return xrequest.get("sale/findPresaleMainCategory", { params: data })
})

/**
 * 根据大类获取预售产品小类列表
 */
export const findPresaleSubCategory = withDefaultsParams({
  sale_date: "", main_category: "", keywords: "", brand_code: "", color: "", grade: ""
})(data => {
  return xrequest.get("sale/findPresaleSubCategory", { params: data })
})

/*
 * 获取预售产品颜色列表
 */
export const findPresaleColor = withDefaultsParams({
  sale_date: "", main_category: "", sub_category: "", keywords: "", brand_code: "", grade: ""
})(data => {
  return xrequest.get("sale/findPresaleColor", { params: data })
})

/**
 * 获取预售品牌列表
 */
export const findPresaleBrand = withDefaultsParams({
  sale_date: "", main_category: "", sub_category: "", keywords: "", color: "", grade: ""
})(data => {
  return xrequest.get("sale/findPresaleBrand", { params: data })
})

/**
 * 获取预售聚合列表
 */
export const findAggregationPresale = withDefaultsParams({
  sale_date: "", main_category: "", sub_category: "",
  keywords: "", brand_code: "", color: "",
  price_sort: "2", timely_count_sort: "2", sale_count_sort: "", grade: ""
})(data => {
  console.log(data)
  return xrequest.get("sale/findAggregationPresale", { params: data, headers: { 'x-pagesize': data.x_pagesize, 'x-page': data.x_page } })
})

/**
 * 获取预售产品详细列表
  */
export const findInfoPresale = withDefaultsParams({
  sale_date: "", main_category: "", sub_category: "", product_name: "", grade: "",
  keywords: "", price_sort: "", timely_count_sort: "", sale_count_sort: "", brand_code: "",
  color: ""
})((data) => {
  return xrequest.get("sale/findInfoPresale", { params: data })
})

/**
 * 订购晚场产品
  */
export const doPresale = withDefaultsParams({
  id: "", order_count: 0, device_type: 1
})((data) => {
  return xrequest.post("sale/doPresale", { ...data })
})

/**
 * 获取晚场订单列表
 */
export const findOrderListPresale = withDefaultsParams({
  sale_date: ""
})((data) => {
  return xrequest.get('/sale/presaleOrder', { params: data })
})

/**
 * 晚场子单修改
 */
export const editPresaleOrderDetail = withDefaultsParams({
  id: "",//子订单ID
  order_count: 0//订购数量（必须大于等于0）
})((data) => {
  return xrequest.post("sale/editePresaleOrderDetail", { ...data })
})














