import request from '@/utils/request';

// 取七牛云token
export const getQiNiuYunToken = () => {
	return request.post('/api/qiniu/token')
}

//账号登录验证码
export const useCaptcha = () => {
	return request.get('/api/member/getVerificationCode')
}

// 账号登录
export const accountLogin = (data) => {
	return request.post('api/member/memberLogin',data)
}

// 手机号码登录验证码
export const useSendCode = (memberPhone) => {
	return request.post('/api/member/sendPhoneCodeForLogin?memberPhone=',memberPhone)
}
// 找回密码验证码
export const sendResetPwdCode = (memberPhone) => {
	return request.post('/api/member/sendPhoneCodeForResetPwd?memberPhone=',memberPhone)
}
//手机号码登录
export const phoneLogin = (data) => {
	return request.post('/api/member/memberLoginByPhone',data)
}

//注册验证码
export const sendRegisterCode = (memberPhone) => {
	return request.post('/api/member/sendPhoneCodeForRegister?memberPhone=',memberPhone)
}

//注册
export const memberRegister = (data) =>{
	return request.post('/api/member/memberRegister',data)
}

// 会员重设密码时的“下一步”校验接口
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-71782929
export const memberCheck = (data) =>{
	return request.post('/api/member/memberCheck',data)
}

// 客户端：会员忘记密码，重设密码
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-71784249
export const memberForgetPassword = (data) =>{
	return request.post('/api/member/memberForgetPassword',data)
}

// 客户端：会员个人中心修改密码
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-71784353
export const memberSetNewPassword = (data) =>{
	return request.post('/api/member/memberSetNewPassword',data)
}

// 获取客户端的会员的基本信息，如会员号、名称、余额、默认收货地址等
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-72051467
export const memberInfoDetail = (data) =>{
	return request.post('/api/member/memberInfoDetail',data)
}

//获取省份列表
export const getProvinceList = (data) => {
	return request.post('/api/district/districtProvinceList',data)
}

// 根据省份获取城市列表
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-70838401
export const getDistrictCityList = (data) => {
	return request.post('/api/district/districtCityList',data)
}

// 根据城市获取区列表
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-70838400

export const getDistrictAreaList = (data) => {
	return request.post('/api/district/districtAreaList',data)
}

// 根据区县获取街道列表
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-70838407
export const getDistrictStreetList = (data) => {
	return request.post('/api/district/districtStreetList',data)
}

// 获取会员信息
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-70838452
export const getMemberInfo = () => {
	return request.post('/api/member/memberInfo',{})
}


// 修改会员个人资料
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-70838469
export const updateMemberInfo = (data) => {
	return request.post('/api/member/memberSave',data)
}

//获取经营范围
export const parametersScopeList = (data) => {
	return request.post('/api/parameters/parametersScopeList',data)
}
//获取经营性质
export const parametersNatureList = (data) => {
	return request.post('/api/parameters/parametersNatureList',data)
}
// 保存会员地址
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-70838441
export const memberAddressSave = (data) => {
	return request.post('/api/memberAddress/memberAddressSave',data)
}

// 获取地址列表
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-70838440
export const memberAddressList = (data = {}) => {
	return request.post('/api/memberAddress/memberAddressList',data)
}

// 获取审批通过的登录会员的收货地址列表
// 接口地址：https://app.apifox.com/link/project/2492336/apis/api-88032851
export const memberAddressApproveList = (data = {}) => {
	return request.post('/api/memberAddress/memberAddressApproveList', data)
}

// 选择指定的收货地址为会员为当前的收货地址
// 接口地址：https://app.apifox.com/link/project/2492336/apis/api-88032989
export const updateDefaultMemberAddress = (data = {}) => {
	return request.post('/api/memberAddress/updateDefaultMemberAddress', data)
}

// 获取地址信息
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-70838439
export const memberAddressInfo = (data) => {
	return request.post('/api/memberAddress/memberAddressInfo',data)
}

// 删除地址
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-70838438
export const memberAddressDelete = (data) => {
	return request.post('/api/memberAddress/memberAddressDelete',data)
}

// 账单查询
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-75051989
export const myFundRecordList = (data) => {
	return request.post('/api/financialManagement/myOrderFundRecordList',data)
}

// 订单查询接口
//接口地址：https://www.apifox.cn/web/project/2492336/apis/api-78827578
export const webMainOrderList = (data) => {
	return request.post('/api/order/webMainOrderList',data)
}

// 订单明细接口（订单查询列表的详情）
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-78827868
export const webOrderDetailList = (data) => {
	return request.post('/api/order/webOrderDetailList',data)
}

// 预订查询接口
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-78828141
export const webMainBookOrderList = (data) => {
	return request.post('/api/order/webMainBookOrderList',data)
}

// 预订订单明细信息（预订查询列表的详情）
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-78828240
export const webBookOrderDetailList = (data) => {
	return request.post('/api/order/webBookOrderDetailList',data)
}

// 商品评价详情接口
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-78934164
export const orderEvaluateInfo = (data) => {
	return request.post('/api/orderEvaluate/orderEvaluateInfo',data)
}

// 商品评价保存接口
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-78934114
export const orderEvaluateSave = (data) => {
	return request.post('/api/orderEvaluate/orderEvaluateSave',data)
}

// 订单物流接口
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-78949638
export const webOrderLogisticsList = (data) => {
	return request.post('/api/order/webOrderLogisticsList',data)
}

// 查询物流轨迹
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-78934026
export const queryTrack = (data) => {
	return request.post('/api/logistics/queryTrack',data)
}


// PC端：订单投诉列表接口
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-79040156
export const webOrderListForComplain = (data) => {
	return request.post('/api/order/webOrderListForComplain',data)
}

// 售后列表接口
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-78996813
export const webOrderComplainList = (data) => {
	return request.post('/api/orderComplain/webOrderComplainList',data)
}

// 售后详情接口
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-78996824
export const webOrderComplainInfo = (data) => {
	return request.post('/api/orderComplain/webOrderComplainInfo',data)
}

// 申请售后原因列表接口
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-78996836
export const parametersComplainReasonList = (data) => {
	return request.post('/api/parameters/parametersComplainReasonList',data)
}

// 客户端：申请售后提交接口
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-70838488
export const orderComplainSave = (data) => {
	return request.post('/api/orderComplain/orderComplainSave',data)
}
//更改售后
export const orderComplainUpdate = (data) => {
	return request.post('/api/orderComplain/orderComplainUpdate',data)
}

// 会员订单确认收货接口
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-78969398
export const orderConfirmReceipt = (data) => {
	return request.post('/api/order/orderConfirmReceipt',data)
}

// 客户端：申请发票订单列表
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-79340453
export const webApplyInvoiceOrderList = (data) => {
	return request.post('/api/financialManagement/invoice/webApplyInvoiceOrderList',data)
}

// 客户端：发票抬头列表
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-79340455
export const webMemberInvoiceTitleList = (data) => {
	return request.post('/api/memberInvoiceTitle/webMemberInvoiceTitleList',data)
}

// 客户端：发票列表
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-79340457
export const webInvoiceList = (data) => {
	return request.post('/api/financialManagement/invoice/webInvoiceList',data)
}

// 新增/编辑发票抬头接口
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-79340678
export const memberInvoiceTitleSave = (data) => {
	return request.post('/api/memberInvoiceTitle/memberInvoiceTitleSave',data)
}

// 客户端：发票提交申请接口
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-79340456
export const webInvoiceSave = (data) => {
	return request.post('/api/invoice/webInvoiceSave',data)
}

//根据发票抬头ID，获取发票详情
//接口地址：https://www.apifox.cn/web/project/2492336/apis/api-79340680
export const memberInvoiceTitleInfo = (data) => {
	return request.post('/api/memberInvoiceTitle/memberInvoiceTitleInfo',data)
}

// 客户端：查看发票接口(查看PDF,查看图片)
// 接口地址：https://www.apifox.cn/web/project/2492336/apis/api-79340459
export const webInvoiceResult = (data) => {
	return request.post('/api/invoice/webInvoiceResult',data)
}

// 申请提现（兼容会员及用户）
// 接口地址：https://app.apifox.com/project/2492336/apis/api-78917533
export const withdrawApply = (data) => {
	return request.post('/api/withdraw/withdrawApply',data)
}

//获取提现记录
// 接口地址：https://app.apifox.com/project/2492336/apis/api-80103302
export const withdrawList = (data) => {
	return request.post('api/withdraw/withdrawList',data)
}

//扣减会员年费
export const handleAnnualFee = (data) => {
	return request.post('api/memberFund/handleAnnualFee',data)
}

//我的VIP情况
export const memberVipPreInfo = (data) => {
	return request.post('api/memberVipPre/memberVipPreInfo',data)
}

//获取会员交易类型
export const getRelateOrderType = (data) => {
	return request.post('api/financialManagement/memberFundRecordList/type',data)
}