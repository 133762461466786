<template>
  <div v-show="show">
    <el-table
      :data="state.list"
      v-loading="state.loading"
      border
      empty-text="没有找到相关信息！"
      size="small"
      show-summary
      table-layout="auto"
      :summary-method="getSummaries"
      style="width: 100%"
      class="u-m-b-15"
    >
      <el-table-column label="品名" align="center" header-align="center">
        <template #default="scope">{{ scope.row.brand + scope.row.product_name + ' ' + scope.row.grade }}</template>
      </el-table-column>
      <el-table-column label="发货日期" align="center">
        <template #default="scope">
          <span>{{ moment(scope.row.sale_date).format('YYYY-MM-DD') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="价格" align="center" width="40">
        <template #default="scope">{{ Number(scope.row.product_price).toFixed(2) }}</template>
      </el-table-column>
      <el-table-column label="数量" align="center" prop="OrderCount" width="80">
        <template #default="scope">
          <el-input-number
            style="width: 80px"
            size="small"
            v-model="scope.row.order_count"
            :min="0"
            v-if="scope.row.can_modify"
          ></el-input-number>
          <span v-else>{{ scope.row.order_count }}</span>
        </template>
      </el-table-column>
      <el-table-column label="佣金" align="center" prop="commission" width="40">
        <template #default="scope">{{ Number(scope.row.commission).toFixed(2) }}</template>
      </el-table-column>
      <el-table-column label="金额" align="center" prop="order_amount" width="40">
        <template #default="scope">{{ Number(scope.row.order_money).toFixed(2) }}</template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="60">
        <template #default="scope">
          <span v-if="scope.row.can_modify == 1">
            <i style="font-size: 20px" class="iconfont icon-bianji" @click="onEditOrder(scope.row)"></i>
            <i style="font-size: 20px" class="iconfont icon-shanchu1" @click="onDeleteOrder(scope.row)"></i>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <div class="sum-box">
      <div v-if="state.totalize.shipping_title">
        运费方案：{{ state.totalize.shipping_title }}（{{ memberInfo.logistics }}-{{ memberInfo.addressCity }}）
      </div>
      <div v-if="state.totalize.predict_title">
        {{ [0, 2].includes(state.totalize.shipping_type) ? '预估重量' : '预估件' }}：{{ state.totalize.predict_title }}
      </div>
      <div v-if="state.totalize.delivery_fee">预估运费：{{ Number(state.totalize.delivery_fee).toFixed(2) }}</div>
      <div v-if="state.totalize.packing_fee">预估配货装箱费：{{ Number(state.totalize.packing_fee).toFixed(2) }}</div>
      <div v-if="state.totalize.discount">折扣：{{ Number(state.totalize.discount).toFixed(2) }}</div>
      <div v-if="state.totalize.TotalGiftOuantity">赠送数量：{{ state.totalize.TotalGiftOuantity }}</div>
      <div v-if="state.totalize.order_actual_amount"
        >预估金额：
        <span style="color: red; font-weight: bold">{{ Number(state.totalize.order_actual_amount).toFixed(2) }}</span>
      </div>
    </div>
    <!-- 提示 -->
    <div class="tips-box">
      <div class="tips"><text>订购提示：</text>点击上方对应按钮查看相应的订单,本场17：00截单。</div>
      <div class="tips"
        >如果您操作不慎订错货品，可以在订购该产品三分钟内点击<i class="iconfont icon-bianji"></i
        ><i class="iconfont icon-shanchu1"></i>来修改或取消该产品订单, 修改或取消后网站会返回该产品的订购金额。</div
      >
      <div class="tips">
        <text>重要提示：</text
        >停盘前的30分钟和订购超过三分钟的，修改和删除键将自动隐藏，不再支持更改，请您留意时间，下单前仔细考虑，感谢您的配合！
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, defineProps, computed, onMounted, watch } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import moment from 'moment';
import { editPresaleOrderDetail, findOrderListPresale } from '@/api/productSale';
import { getAfterNoonSaleDate } from '@/common';
import { startLoading } from '@/common';
import { userStore } from '@/store/user';

const emit = defineEmits(['update']);

const props = defineProps({
  memberInfo: {
    type: Object,
    default: {}
  },
  show: {
    type: Boolean,
    default: false
  }
});

const userInfo = userStore();

/**
 * 取预购订单列表
 * @param {无}
 */
const state = reactive({
  loading: true,
  list: [],
  totalize: {}
});

onMounted(() => {
  getPresaleOrderList();
});

watch(
  () => props.show,
  (newValue, oldValue) => {
    if (newValue) {
      getPresaleOrderList();
    }
  }
);

const getSummaries = param => {
  const { columns, data } = param;
  const sums = [];

  if (Array.isArray(columns)) {
    columns.forEach((value, idx) => {
      switch (value.property) {
        case 'OrderCount':
          sums[idx] = Number(state.totalize?.order_total_count).toFixed(0);
          break;
        case 'commission':
          sums[idx] = Number(state.totalize?.commission).toFixed(2);
          break;
        case 'order_amount':
          sums[idx] = Number(state.totalize?.order_total_amount).toFixed(2);
          break;
        default:
          sums[idx] = '';
          break;
      }
    });
  }
  return sums;
};

const getPresaleOrderList = async () => {
  // Object.keys(state.totalize).forEach(key => state.totalize[key] = 0)
  state.loading = true;
  try {
    const { data } = await findOrderListPresale({
      sale_date: getAfterNoonSaleDate()
    });
    state.totalize = data.order || [];
    state.list = data.details.map(item => {
      return {
        ...item,
        can_modify: isCanModify(item.order_time)
      };
    });
    console.log('晚场订单', data);
  } catch (error) {
    console.log(error);
  } finally {
    state.loading = false;
  }
};

/**
 * 判断是否可以修改子单
 */
function isCanModify(time) {
  let current = moment();
  let sixteen = moment().hour(18).minute(0).second(0);
  let order_time = moment(moment(time).utc().format('YYYY-MM-DD HH:mm:ss'));
  if (current.isAfter(sixteen)) {
    return false;
  }
  if (current.diff(order_time, 'minute') > 3) {
    return false;
  }
  console.log('44444', time, order_time.format('HH:mm:ss'), current.diff(order_time, 'minutes', true));
  return true;
}

/**
 * 是否显示修改删除键
 * @param {object} event
 */
// const isShow = computed(() => {
// 	return function(event) {
// 		if (event.SaleType == 3) {
// 			return moment(moment().format('YYYY-MM-DD HH:mm')).diff(moment(event.OrderTime), 'minutes') < 10 || event.CanModify == 1
// 		} else {
// 			return moment(moment().format('YYYY-MM-DD HH:mm')).diff(moment(event.OrderTime), 'minutes') < 120 || event.CanModify == 1
// 		}
// 	}
// })

/**
 * 修改订单
 * @param {object} event
 */
const onEditOrder = async event => {
  console.log(event);
  if (!event.order_count) {
    ElMessage({ message: '数量不能为空', type: 'warning' });
    return;
  }
  startLoading(async () => {
    const res = await editPresaleOrderDetail({
      id: event.id,
      order_count: event.order_count
    });
    ElMessage({ type: 'success', message: '编辑成功' });
    getPresaleOrderList();
    userInfo.getMemberInfo();
  });
};

/**
 * 删除订单
 * @param {object} event
 */
const onDeleteOrder = event => {
  ElMessageBox.alert('确定删除该订单吗？', '提示', {
    type: 'warning',
    cancelButtonText: '取消',
    confirmButtonText: '确定'
  }).then(async () => {
    startLoading(async () => {
      const params = { id: event.id, order_count: 0 };
      const res = await editPresaleOrderDetail(params);
      ElMessage({ type: 'success', message: '删除成功' });
      getPresaleOrderList();
      userInfo.getMemberInfo();
    });
  });
};

function updateData(params) {
  getPresaleOrderList();
}

defineExpose({ updateData });
</script>

<style lang="scss" scoped>
@import '@/assets/style/same.scss';
</style>
