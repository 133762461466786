<template>
	<div class="login-box">
		<el-form ref="ruleFormRef" :model="registerForm" :rules="rules" size="large">
			<div class="title">申请注册</div>
			<el-form-item prop="userName">
				<el-input v-model="registerForm.userName" type="text" placeholder="请输入名称" :prefix-icon="User"></el-input>
			</el-form-item>
			<el-form-item prop="contacts">
				<el-input v-model="registerForm.contacts" type="text" placeholder="请输入联系人" :prefix-icon="ChatLineRound"></el-input>
			</el-form-item>
			<el-form-item prop="password">
				<el-input v-model="registerForm.password" type="password" placeholder="请输入密码" :prefix-icon="Lock"></el-input>
			</el-form-item>
			<el-form-item prop="confirmPassword">
				<el-input v-model="registerForm.confirmPassword" type="password" placeholder="请再次输入密码" :prefix-icon="Lock"></el-input>
			</el-form-item>
			<el-form-item prop="province">
				<el-select v-model="registerForm.province" filterable placeholder="请选择收货省份" style="width:100%;"
					class="address-icon">
					<template #prefix>
						<span><el-icon>
								<Location />
							</el-icon></span>
					</template>
					<el-option v-for="item in provinceOptions" :key="item.Province" :label="item.Province"
						:value="item.Province" />
				</el-select>
			</el-form-item>
			<el-form-item prop="memberPhone">
				<el-input v-model="registerForm.memberPhone" type="text" placeholder="请输入手机号码"
					:prefix-icon="Iphone"></el-input>
			</el-form-item>
			<el-form-item prop="code">
				<el-input v-model="registerForm.code" type="text" placeholder="请输入验证码" :prefix-icon="CircleCheck"
					:class="{'input-width-70':!sms.disabled,'input-width-60':sms.disabled}"></el-input>
				<el-button v-if="!sms.disabled" type="primary" @click="sendCode"
					style="margin-left:10px !important;">发送验证码</el-button>
				<el-button v-else type="primary" disabled style="margin-left:10px !important;">{{ sms.count }}
					秒后重新发送</el-button>
			</el-form-item>
			<div class="" style="display:inline-flex;align-items: center;">
				<el-checkbox v-model="isAgree" size="large" class="checkbox"></el-checkbox><text class="privacy">阅读并接受<a
						@click="privacyVisible=true">《隐私协议》</a>和<a @click="memberVisible=true">《会员协议》</a></text>
			</div>
			<div class="u-center">
				<el-button class="sumbit-btn" type="primary" @click="submitForm(ruleFormRef)">注册</el-button>
				
			</div>
			<div class="tips">温馨提示：
			<div>1、如需开发票，会员名称需要和发票抬头一致；</div>
			<div>2、海外客户请直接联系王总：15911512725（微信同号）；</div>
			</div>
		</el-form>
	</div>
	<el-dialog v-model="privacyVisible" title="隐私协议" width="1000px">
		<privacy-agreement></privacy-agreement>
	</el-dialog>
	
	<el-dialog v-model="memberVisible" title="会员协议" width="1000px">
		<member-agreement></member-agreement>
	</el-dialog>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted
	} from "vue";
	import { ElMessage, ElMessageBox} from 'element-plus';
	import {
		useRouter
	} from 'vue-router';
	import {
		useSendCode,
		getProvinceList,
		sendRegisterCode,
		memberRegister
	} from '@/api/user';
	import {
		User,
		Iphone,
		ChatLineRound,
		CircleCheck,
		Location,
		Lock
	} from '@element-plus/icons-vue'
	
	import memberAgreement from './memberAgreement.vue';
	import privacyAgreement from './privacyAgreement.vue';
	
	onMounted(() => {
		getProvince();
	});

	//表单
	const ruleFormRef = ref();
	const isAgree = ref();
	const registerForm = reactive({
		userName: '',
		contacts: '',
		province: '',
		memberPhone: '',
		code: '',
		password:'',
		confirmPassword:''
	});
	//隐私协议
	const privacyVisible = ref(false);
	const memberVisible = ref(false);
	
	// 获取省列表
	const provinceOptions = ref([]);
	const getProvince = () => {
		return getProvinceList({}).then(res => {
			provinceOptions.value = res.data.records;
		})
	}
	//验证两次密码是否一致
		const validatePassword = (rule, value, callback) => {
			if (value !== registerForm.password && registerForm.password !== '') {
				callback(new Error("两次输入的密码不一致"));
			} else {
				callback();
			}
	};
	//表单验证
	const rules = reactive({
		userName: [{
			required: true,
			message: '请输入名称',
			trigger: 'blur'
		}],
		contacts: [{
			required: true,
			message: '请输入联系人',
			trigger: 'blur'
		}],
		password: [{
			required: true,
			message: '请输入密码',
			trigger: 'blur'
		}],
		confirmPassword: [{
			required: true,
			message: '请再次输入密码',
			trigger: 'blur'
		}, {
			required: true,
			validator: validatePassword,
			message: '两次输入的密码不一致',
			trigger: "blur"
		}],
		province: [{
			required: true,
			message: '请选择收货省份',
			trigger: ["blur",'change']
			
		}],
		memberPhone: [{
			required: true,
			message: '输入手机号码',
			trigger: 'blur'
		}, {
			pattern: /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[189]))\d{8}$/,
			message: '手机号码格式不正确',
			trigger: 'blur'
		}],
		code: [{
			required: true,
			message: '请输入验证码',
			trigger: 'blur'
		}, ]
	});

	//发送验证码
	const sendCode = () => {
		sendRegisterCode({
			memberPhone: registerForm.memberPhone
		}).then(() => {
			timerHandler()
		})
	};

	// 短信计时器
	const sms = reactive({
		disabled: false,
		total: 300,
		count: 0
	})

	// 计时器处理器
	const timerHandler = () => {
		sms.count = sms.total
		sms.disabled = true

		let timer = setInterval(() => {
			if (sms.count > 1 && sms.count <= sms.total) {
				sms.count--
			} else {
				sms.disabled = false
				clearInterval(timer)
			}
		}, 1000)
	}



	//表单提交
	const router = useRouter();

	const submitForm = (form) => {
		form.validate((valid, fields) => {
			if (valid) {
				if (!isAgree.value) {
					ElMessage({
						message: '请阅读并接受《隐私协议》',
						type: 'error',
						duration:1000
					})
					return
				} else {
					let param = {
						name: registerForm.userName,
						linkMan: registerForm.contacts,
						phone: registerForm.memberPhone,
						smsCode: registerForm.code,
						province: registerForm.province,
						password: registerForm.password,
						confirmPassword: registerForm.confirmPassword,
					}
					memberRegister(param).then(res => {
						ElMessage({
							message: '注册成功',
							type: 'success',
							duration:1000
						})
						setTimeout(() => {
							router.push({
								path: '/login'
							})
						}, 2000)
					});
				}
			}
		})
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/style/login.scss';
	.el-form-item--large{
		margin-bottom: 0.09rem;
	}
	.title {
		padding-bottom: 20px;
		text-align: center;
		font-size: 24px;
	}

	.tips {
		font-size: 16px;
		font-weight: 400;
		color: #DC0000;
		line-height:20px;
	}

	.address-icon {
		background: url('@/assets/image/addressIcon.png') no-repeat;
		//padding-left:22px;
	}

	.checkbox .el-checkbox__inner {
		width: 16px !important;
		height: 16px !important;
	}

	.input-width-60 {
		width: 60%;
	}

	.input-width-70 {
		width: 70%;
	}
</style>